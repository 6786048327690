//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['visible', 'ids'],

    data() {
        return {
            expandMyTokens: false,
            showDialog: false,
            pipId: -1,
            fullscreen: false,
            sortedIds: [],
            previewId: null,
            timeout: null,
            pipHover: false,
            visibleTokens: 20,
            mapInitialized: false
        };
    },

    watch: {
        visible(show) {
            this.showDialog = true;
            this.expandMyTokens = false;
            this.pipId = -1;
            this.visibleTokens = 20;
            this.previewId = null;
            this.sortedIds = this.ids.sort((a, b) => a - b);
            this.updateEvents();
            if(!this.mapInitialized){
            setTimeout(() => {

            this.mymap = L.map(this.$refs.imageMap,{
                crs: L.CRS.Simple,
                maxBounds: this.bounds,
                maxBoundsViscosity: 0.5,
                zoomDelta: 0.5,
                zoomSnap: 0.01,
                attributionControl:false
            }).setView(this.bounds.getCenter(), 0);
            this.mapInitialized = true;
            this.tileLayer = L.tileLayer('https://decentral-art.com/art-parts/{z}/{x}/{y}.jpg', {
              attribution: '',
              maxZoom: 9,
              minZoom: 0,
              bounds: this.bounds,
              tileSize: 256,
              noWrap: true,
          }).addTo(this.mymap);

            this.mymap.fitBounds(this.bounds);

            this.layerGroup = L.featureGroup([]);
            this.layerGroup.on('click', (e) => {
                this.showPreview(e.layer.options.x, e.layer.options.y);
            });

            this.hoverGroup = L.featureGroup([]);
            this.hoverGroup.addTo(this.mymap);

            this.mymap.on('zoomend', () => {
                var z = this.mymap.getZoom();

                if (z > 4) {
                   return this.layerGroup.addTo(this.mymap);
               }

               return this.layerGroup.removeFrom(this.mymap);
           });
            this.updateMapClicableParts();
           },200);
        } else {
            this.mymap.fitBounds(this.bounds);
        }
        },

        visibleTokens() {
            this.updateEvents();
        },

        "token.image": function () {
            if(this.tileLayer)
                this.tileLayer.setUrl("https://decentral-art.com/art-parts/{z}/{x}/{y}.jpg?t="+Date.now());
            },
            "token.lastTokenIndex": function() {
                this.updateMapClicableParts();
            }
    },

    computed: {
        myTokens: function() {
            return Object.keys(this.token.owning[this.$store.getters['token/myAddressIndex']] || {});
        },
        limitedMyTokens: function() {
            return this.myTokens.slice(0, this.visibleTokens);
        },

        token() {
            return this.$store.state.token;
        },

        enabledPositions() {
            let enabled = [];
            for (var i = 0; i < this.ids.length; i++) {
                enabled.push(this.$DB[this.ids[i]].x + '-' + this.$DB[this.ids[i]].y);
            }
            if (this.expandMyTokens) {
                for (var i = 0; i < this.myTokens.length; i++) {
                    enabled.push(this.$DB[this.myTokens[i]].x + '-' + this.$DB[this.myTokens[i]].y);
                }
            }
            return enabled;
        }
    },

    methods: {
        imageSrc(id) {
            if(!this.$DB[id]) return "";
            if(this.token.vipIndexes.indexOf(id) == -1){
                return "https://api.decentral-art.com/token-preview/"+id+"/ordinary";
            }
            return "https://api.decentral-art.com/token-preview/"+id+"/vip";
        },
        updateMapClicableParts() {
            if(typeof this.layerGroup == "undefined") return;
            this.layerGroup.clearLayers();
                let dLat = 1235/2**9;
                let dLng = 543/2**9;
                if(this.token.lastTokenIndex > -1){
                for (var i = 0; i <= this.token.lastTokenIndex; i++) {
                    let t = this.$DB[i];
                    this.layerGroup.addLayer(L.rectangle([
                        [this.start.lat-(dLat*t.y), this.start.lng+dLng + (t.x*dLng)],
                        [this.start.lat-dLat-(dLat*t.y), this.start.lng + (t.x*dLng)],
                        ], {color: "#d4af37de", fillOpacity: 0, opacity: 1, weight: 1, token: i, x: t.x, y: t.y}));
                }
            }
        },

        updateEvents() {
            setTimeout(() => {
                let chips = document.getElementsByClassName('chipAt');
                for (var i = 0; i < chips.length; i++) {
                    let mouseEnter = e => {
                            let d = this.$DB[e.target.id.split('-')[1]];
                            let dLat = 1235/2**9;
                let dLng = 543/2**9;
                            this.hoverGroup.addLayer(L.rectangle([
                        [this.start.lat-(dLat*d.y), this.start.lng+dLng + (d.x*dLng)],
                        [this.start.lat-dLat-(dLat*d.y), this.start.lng + (d.x*dLng)],
                        ], {color: "#d4af37de", fillOpacity: 0, opacity: 1, weight: 5, token: i, x: d.x, y: d.y}));
                        },
                        mouseLeave = e => {
                            this.hoverGroup.clearLayers();
                        };
                    chips[i].removeEventListener('mouseenter', mouseEnter);
                    chips[i].removeEventListener('mouseleave', mouseLeave);
                    chips[i].addEventListener('mouseenter', mouseEnter);
                    chips[i].addEventListener('mouseleave', mouseLeave);
                }
            }, 200);
        },

        async copyURL(url) {

            const container = document.querySelector('.v-dialog');

            try {
                await this.$copyText(url, container).then(() => {
                    this.snackbar = true;
                });

            } catch (e) {
                console.error(e);
            }

            this.$emit('showAlert', true);

            setTimeout(() => {
                this.$emit('showAlert', false);
            }, 900);
        },

        hoverItem(x, y, e) {
            if (this.isBuyed(x, y)) {
                this.previewId = this.$PTID[x + '-' + y];
                if (e.type == 'mouseleave') {
                    this.showPip = false;
                    this.previewId = -1;
                }
            }
        },

        isBuyed(x, y) {
            return this.enabledPositions.indexOf(x + '-' + y) != -1;
        },

        showPreview(x, y, e) {
                this.pipId = this.previewId = this.$PTID[x + '-' + y];
        },

        maximalize() {
            let el = this.$refs.art;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (el.requestFullscreen) {
                    el.requestFullscreen();
                } else if (el.mozRequestFullScreen) {
                    el.mozRequestFullScreen();
                } else if (el.webkitRequestFullscreen) {
                    el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (el.msRequestFullscreen) {
                    el.msRequestFullscreen();
                }
            }
        }
    },

    mounted() {
        this.previewId = null;
        document.addEventListener('fullscreenchange', event => {
            this.fullscreen = !this.fullscreen;
        });
        this.start = {lat: 0, lng: 0};
        let end = {lat: -118, lng: 236};
        this.bounds = new L.LatLngBounds(new L.LatLng(this.start.lat, this.start.lng), new L.LatLng(end.lat, end.lng));
    }
};
