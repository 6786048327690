//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        buttonHref: {
            type: String,
            default: ''
        },

        tokenId: {
            type: Number,
            default: 0
        },

        mobile: {
            type: Boolean,
            default: false
        },

        buttonText: {
            type: String,
            default: 'Buy'
        },

        buttonColor: {
            type: String,
            default: '#D4AF37'
        },
        
        buttonDisabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            windowWidth: 0,
            socialMedia: {
                text: 'Piece-by-piece art Pictures split into pieces. Here is my part of the picture:',
                hashtags: 'decentralart,nft',
                via: 'art_decentral'
            }
        };
    },

    computed: {
        token() {
            return this.$store.state.token;
        },
        previewIconCollor(){
            if(!this.$DB[this.tokenId]) return "";
            return this.token.vipIndexes.indexOf(this.tokenId) == -1 ? "" : "#d4af37";
        }
    },

    methods: {
        getLength(number) {
            return number.toString().length;
        },

        imageSrc(id) {
            if(!this.$DB[id]) return "";
            if(this.token.vipIndexes.indexOf(id) == -1){
                return "https://api.decentral-art.com/token-preview/"+id+"/ordinary";
            }
            return "https://api.decentral-art.com/token-preview/"+id+"/vip";
        },

        async copyURL(url) {
            try {
                await this.$copyText(url);
            } catch (e) {
                console.error(e);
            }

            this.$emit('showAlert', true);

            setTimeout(() => {
                this.$emit('showAlert', false);
            }, 900);
        },

        screenWidth(e) {
            this.windowWidth = e.currentTarget.innerWidth;
        }
    },

    mounted() {},

    created() {
        window.addEventListener('resize', this.screenWidth);
    },

    destroyed() {
        window.removeEventListener('resize', this.screenWidth);
    }
};
