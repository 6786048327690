//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['blockchain'],
    data() {
        return {
            panel: [],
            searchText: null,
            items: [],
            isLoading: false,
            search: null,
            windowWidth: 0,
            showToolTip: [],
            selectedId: [],
            showPreviewDialog: 0,
            phases: [],
            showPreviewImage: false,
            showAlert: false
        };
    },

    destroyed() {
        window.removeEventListener('resize', this.screenWidth);
    },

    mounted() {
         window.addEventListener('resize', this.screenWidth);

        this.windowWidth = window.innerWidth;

        let interval = setInterval(() => {
            if (!this.token.loading) {
                if (localStorage.getItem('market') == 'buy') {
                    this.panel.push(this.token.actualPhase);
                    this.$el.querySelector(`#phase-${this.token.actualPhase}`).scrollIntoView({ behavior: 'smooth' });

                    localStorage.removeItem('market');
                    clearInterval(interval);
                }
            }
        }, 200);

        setTimeout(() => clearInterval(interval), 5000);
    },

    methods: {
        buttonHref(p, id) {
            const indexesToSell = this.$store.getters['token/indexesToSell'];

            if (indexesToSell.indexOf(id) != -1 && this.panel.indexOf(p.phase) != -1 && p.phase <= this.token.actualPhase) {
                return `/after-market?buy=${id}`;
            } else {
                return `/preview/details/${id}`;
            }
        },

        buttonText(p, id) {
            const indexesToSell = this.$store.getters['token/indexesToSell'];

            if (indexesToSell.indexOf(id) != -1 && this.panel.indexOf(p.phase) != -1 && p.phase <= this.token.actualPhase) {
                return this.$t('buttons.buy');
            } else {
                return 'more';
            }
        },

        getWin(p, bi) {
            let isVIP = this.token.vipIndexes.indexOf(p.ids[bi]) != -1;
            let isWinner = this.isInWinners(bi, p.winners);
            if (isVIP && isWinner)
                return [
                    {
                        show: true,
                        win: p.winAmount + p.prize / 2,
                        link: 'https://api.decentral-art.com/phase-winners-tx/' + p.phase
                    }
                ];
            else if (isVIP) return [{ show: true, win: p.prize / 2, link: 'https://api.decentral-art.com/vip-tx/' + p.ids[bi] }];
            else if (isWinner)
                return [{ show: true, win: p.winAmount, link: 'https://api.decentral-art.com/phase-winners-tx/' + p.phase }];
            else return [{ show: false }];
        },
        screenWidth(e) {
            this.windowWidth = e.currentTarget.innerWidth;
        },

        showBuyModal() {
            this.$emit('showBuyModal');
        },

        pom(a, b) {
            let r = 0;
            for (var i = 0; i < a; i++) {
                r += this.token.phases[i].buyers.length;
            }
            r += b;
            return r;
        },

        preview(a) {
            this.selectedId = [a];
            this.showPreviewDialog++;
        },

        status(i, o) {
            if (i < o) {
                return [this.$t('tables.statusFinished'), 'text--lighten-1 grey--text', 'check-circle'];
            } else if (i == o) {
                return [this.$t('tables.statusInProgress'), 'black--text', 'progress-clock'];
            } else {
                return [this.$t('tables.statusNotStarted'), 'text--lighten-1 grey--text', 'progress-close'];
            }
        },

        isInWinners(search, array) {
            return array.indexOf(search) != -1;
        },
    },

    computed: {
        token() {
            return this.$store.state.token;
        },

        blockchainData() {
            if (this.blockchain) return this.blockchain.getData();
            else return false;
        },

        filteredPhases: function () {
            if (this.search == null) return this.token.phases;

            let phases = JSON.parse(JSON.stringify(this.token.phases));
            if (this.search.data.type == 'phase') {
                return phases.filter(p => p.phase == this.search.data.val);
            } else if (this.search.data.type == 'token') {
                return phases.filter(p => {
                    let index = p.ids.indexOf(this.search.data.val);
                    if (index != -1) {
                        p.show = p.show.map(() => false);
                        p.show[index] = true;
                        return true;
                    } else return false;
                });
            } else if (this.search.data.type == 'wallet') {
                return phases.filter(p => {
                    let found = false;
                    p.buyers.map((b, bi) => {
                        if (b.toUpperCase() == this.search.data.val) {
                            p.show[bi] = true;
                            found = true;
                        } else p.show[bi] = false;
                    });
                    return found;
                });
            } else return phases;
        }
    },

    watch: {
        'blockchainData.phases'(a) {},

        searchText(val) {
            let addresses = [],
                tokens = [],
                phasesArray = [];
            if (val == '' || val == null) {
                this.items = [];
                return;
            }
            if (val.indexOf('Phase: ') == 0) val = val.substring(7);
            if (val.indexOf('Wallet: ') == 0) val = val.substring(8);
            if (val.indexOf('Token ID: ') == 0) val = val.substring(10);
            if (this.isLoading) return;

            this.isLoading = true;
            this.token.phases.map(p => {
                if (val.length < 6 && p.phase.toString().indexOf(parseFloat(val)) != -1) {
                    phasesArray.push({ name: 'Phase: ' + p.phase, data: { type: 'phase', val: p.phase } });
                }
                p.buyers.map((b, i) => {
                    if (val.length < 6 && p.ids[i].toString().indexOf(parseFloat(val)) != -1) {
                        tokens.push({ name: 'Token ID: ' + p.ids[i], data: { type: 'token', val: p.ids[i] } });
                    } else if (
                        b.toUpperCase().indexOf(val.toUpperCase()) != -1 &&
                        !addresses.find(a => a.data.val == b.toUpperCase())
                    ) {
                        addresses.push({ name: 'Wallet: ' + b, data: { type: 'wallet', val: b.toUpperCase() } });
                    } else {
                    }
                });
            });

            this.isLoading = false;
            this.items = [...phasesArray.slice(0, 3), ...tokens.slice(0, 3), ...addresses.slice(0, 3)];
        }
    }
};
