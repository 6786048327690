var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobile),expression:"mobile"}]},[_c('v-tooltip',{attrs:{"left":"","content-class":"preview-image"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":_vm.previewIconCollor}},[_vm._v(" mdi-image-search-outline ")])],1)],1)]}}])},[_vm._v(" "),_c('div',[_c('div',{staticClass:"watermark"},[_c('img',{staticClass:"picture",attrs:{"color":"#0FF","src":_vm.imageSrc(_vm.tokenId)}})]),_vm._v(" "),_c('div',{class:{ 'd-flex flex-column': _vm.getLength(_vm.tokenId) > 3 }},[_vm._v("\n                    Token "),_c('span',[_vm._v("#"+_vm._s(_vm.tokenId))])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('a',{staticClass:"twitter-share-button mr-3",attrs:{"href":("https://twitter.com/intent/tweet?text=" + (_vm.$t(
                            'components.socialMedia.text'
                        )) + "&url=https://decentral-art.com/preview/details/" + _vm.tokenId + "&hashtags=" + (_vm.$t(
                            'components.socialMedia.hashtags'
                        )) + "&via=" + (_vm.$t('components.socialMedia.via'))),"target":"_blank"}},[_c('v-icon',{staticClass:"pointer share"},[_vm._v("mdi-twitter")])],1),_vm._v(" "),_c('div',{staticClass:"mr-3",on:{"click":function($event){return _vm.copyURL(("https://decentral-art.com/preview/details/" + _vm.tokenId))}}},[_c('v-icon',{staticClass:"pointer share"},[_vm._v("mdi-share-variant")])],1),_vm._v(" "),_c('div',{staticClass:"dark-buttons ml-0 mr-0",class:{ 'mx-4': _vm.windowWidth > 410 },on:{"click":function($event){return _vm.$emit('selectedButton', _vm.tokenId)}}},[_c('v-btn',{attrs:{"href":_vm.buttonHref,"small":_vm.windowWidth < 425,"color":_vm.buttonColor,"disabled":_vm.buttonDisabled,"block":""}},[_vm._v(_vm._s(_vm.buttonText))])],1)])])])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobile),expression:"!mobile"}]},[_c('v-tooltip',{attrs:{"left":"","content-class":"preview-image"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"deep-purple","block":"","text":""},on:{"click":function($event){return _vm.$emit('selectedToken', _vm.tokenId)}}},'v-btn',attrs,false),on),[_vm._v("\n                    "+_vm._s(_vm.$t('buttons.preview'))+"\n                ")])]}}])},[_vm._v(" "),_c('div',[_c('div',{staticClass:"watermark"},[_c('img',{staticClass:"picture",attrs:{"color":"#0FF","src":_vm.imageSrc(_vm.tokenId)}})]),_vm._v(" "),_c('div',{class:{ 'd-flex flex-column': _vm.getLength(_vm.tokenId) > 3 }},[_vm._v("\n                    Token "),_c('span',[_vm._v("#"+_vm._s(_vm.tokenId))])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('a',{staticClass:"twitter-share-button mr-3",attrs:{"href":("https://twitter.com/intent/tweet?text=" + (_vm.$t(
                            'components.socialMedia.text'
                        )) + "&url=https://decentral-art.com/preview/details/" + _vm.tokenId + "&hashtags=" + (_vm.$t(
                            'components.socialMedia.hashtags'
                        )) + "&via=" + (_vm.$t('components.socialMedia.via'))),"target":"_blank"}},[_c('v-icon',{staticClass:"pointer share"},[_vm._v("mdi-twitter")])],1),_vm._v(" "),_c('div',{staticClass:"mr-3",on:{"click":function($event){return _vm.copyURL(("https://decentral-art.com/preview/details/" + _vm.tokenId))}}},[_c('v-icon',{staticClass:"pointer share"},[_vm._v("mdi-share-variant")])],1),_vm._v(" "),_c('div',{staticClass:"dark-buttons ml-0 mr-0",class:{ 'mx-4': _vm.windowWidth > 410 },on:{"click":function($event){return _vm.$emit('selectedButton', _vm.tokenId)}}},[_c('v-btn',{attrs:{"href":_vm.buttonHref,"small":_vm.windowWidth < 425,"color":_vm.buttonColor,"disabled":_vm.buttonDisabled,"block":""}},[_vm._v(_vm._s(_vm.buttonText))])],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }